import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BoxShadow, SectionTitle, StyledH1 } from "../../styles/globalStyles";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import styled from "styled-components";
import { StyledPara } from "./AboutUsSection";

const SectionWrap = styled.section`
  padding: 80px 0;
`;

const RatingWrap = styled.div`
  background-color: rgb(40, 40, 40);
  border-radius: 4px;
  overflow: hidden;
  padding: 70px 150px;
  position: relative;
  color: #fff;
  ${BoxShadow}

  @media only screen and (max-width: 991px) {
    padding: 70px;
  }

  &::after {
    content: "";
    position: absolute;
    right: 40px;
    bottom: -90px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f,
      inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff,
      0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  }

  &::before {
    content: "";
    position: absolute;
    left: -40px;
    top: -90px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f0f,
      inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff,
      0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  }

  @media only screen and (max-width: 767px) {
    padding: 50px 70px;
  }

  .col-md-4 {
    position: relative;

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        top: 20%;
        bottom: 20%;
        right: 0;
        background-color: purple;

        @media only screen and (max-width: 767px) {
          height: 2px;
          width: auto;
          inset: auto 30% 0;
        }
      }
    }
  }
`;

const RatingItem = styled.div`
  text-align: center;

  @media only screen and (max-width: 767px) {
    padding: 20px;
  }

  h1 {
    margin-bottom: 0;
    font-size: 42px;
  }

  p {
    font-size: 18px;
    margin: 0;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  font-size: 22px;
  text-transform: Capitalize;
  margin-bottom: 30px;

  &::after {
    display: none;
  }
`;

const SocialRatingSection = () => {
  return (
    <SectionWrap>
      <Container>
        <RatingWrap>
          <StyledSectionTitle
            className="text-center"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            Join Us And Capture Your Memories
          </StyledSectionTitle>
          <Row data-aos="fade-up" data-aos-delay="100">
            <Col md={4}>
              <RatingItem>
                <StyledH1>
                  <CountUp
                    suffix="+"
                    start={0}
                    decimal="."
                    end={500}
                    redraw={true}
                  >
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </StyledH1>
                <StyledPara>Clients</StyledPara>
              </RatingItem>
            </Col>
            <Col md={4}>
              <RatingItem>
                <StyledH1>
                  <CountUp
                    suffix="+"
                    start={0}
                    decimal="."
                    end={6}
                    redraw={true}
                  >
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </StyledH1>
                <StyledPara>Cities</StyledPara>
              </RatingItem>
            </Col>
            <Col md={4}>
              <RatingItem>
                <StyledH1>
                  <CountUp
                    suffix="+"
                    start={0}
                    decimal="."
                    end={2}
                    redraw={true}
                  >
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </StyledH1>
                <StyledPara>Product Lines</StyledPara>
              </RatingItem>
            </Col>
          </Row>
        </RatingWrap>
      </Container>
    </SectionWrap>
  );
};

export default SocialRatingSection;
