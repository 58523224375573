import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Fancybox from "../../components/Fancybox";
import GlobalBannerComp from "../../components/GlobalBannerComp";
import Layout from "../../components/Layout/Layout";
import {
  // CatDescription,
  CatSectionWrap,
  // CatStyledPara,
  ImageContainer,
  ImageWrap,
  MasonaryWrap,
} from "../../styles/globalStyles";

const CouplesPage = ({ data }) => {
  return (
    <Layout parent_path="/product">
      <GlobalBannerComp titleText="Couples" />

      <CatSectionWrap>
        <Container>
          {/* <CatDescription data-aos="fade-up" data-aos-delay="100">
            <CatStyledPara>
              Couple Hand Casting - 3D Hands Casting are specialized in
              Couple Hand Casting. Behind the happiness of each couple lies the
              effort of two people who must have fought obstacles,
              dissimilarities between the two and expectations from time and
              again but still they triumphed over all and embraced life with
              love. To benchmark this selfless bond for your generations to
              replicate, we bring you the castings of the souls in love forever.
            </CatStyledPara>

            <CatStyledPara>
              Couple Hand Casting is Celebrate the bond of togetherness and love
              for lifetime with our 3D Couple Hand Casting. Be it holding hands
              on your anniversary or wedding, nothing displays the intimacy of
              your relationship better than our 3D couple hand castings. Create
              an everlasting impression that is special and unique that people
              will be talking about forever.
            </CatStyledPara>
          </CatDescription> */}
          <Row>
            <Col md={12}>
              <MasonaryWrap>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3, 1400: 4 }}
                >
                  <Masonry gutter="20px">
                    {data.couplesImages.edges.map((item, i) => {
                      return (
                        <ImageContainer key={i}>
                          <Fancybox options={{ infinite: false }}>
                            <a
                              data-fancybox="couples"
                              href={
                                item.node.childImageSharp.gatsbyImageData.images
                                  .fallback.src
                              }
                            >
                              <ImageWrap>
                                <GatsbyImage
                                  image={
                                    item.node.childImageSharp.gatsbyImageData
                                  }
                                  alt={item.node.name}
                                />
                              </ImageWrap>
                            </a>
                          </Fancybox>
                        </ImageContainer>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </MasonaryWrap>
            </Col>
          </Row>
        </Container>
      </CatSectionWrap>
    </Layout>
  );
};

export const blessingQuery = graphql`
  query {
    couplesImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "couples" }
      }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default CouplesPage;
