import React from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";

const StyledForm = styled(Form)`
  .form-label {
    font-family: "Montserrat-Medium";
    color: rgb(110, 111, 114);
    font-size: 13px;
  }

  .form-control {
    outline: none;
    box-shadow: none;
    font-size: 13px;
    padding: 0.6rem 0.75rem;
  }
`;

const FormLeaveMessage = () => {
  return (
    <StyledForm
      target="_blank"
      action="https://formsubmit.co/51d0f9e7411fea86354fc971591fbcd6"
      method="POST"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      <Form.Group className="mb-3" controlId="formBasicName">
        <Form.Label>Full name</Form.Label>
        <Form.Control type="text" name="name" required />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" name="email" required />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPhone">
        <Form.Label>Phone number</Form.Label>
        <Form.Control type="phone" name="phone" required />
      </Form.Group>

      <Form.Group className="mb-4" controlId="formBasicEmail">
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={4} name="message" />
      </Form.Group>

      <Form.Group className="text-end">
        <Button variant="primary" type="submit">
          Send Message
        </Button>
      </Form.Group>
    </StyledForm>
  );
};

export default FormLeaveMessage;
