import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Fancybox from "../../components/Fancybox";
import GlobalBannerComp from "../../components/GlobalBannerComp";
import Layout from "../../components/Layout/Layout";
import {
  // CatDescription,
  CatSectionWrap,
  // CatStyledPara,
  ImageContainer,
  ImageWrap,
  MasonaryWrap,
} from "../../styles/globalStyles";

const FamilyPage = ({ data }) => {
  return (
    <Layout parent_path="/product">
      <GlobalBannerComp titleText="Family" />

      <CatSectionWrap>
        <Container>
          {/* <CatDescription>
            <CatStyledPara data-aos="fade-up" data-aos-delay="100">
              There is no better friend than having a sibling for they care,
              love and protect each other and saluting this unique bond, we
              endeavor to frame that affection as is and with no filtration for
              we understand love is divine. The impression of siblings together
              is the perfect gift for them to reminisce the bond as they grow up
              and for you to count your blessings twice.
            </CatStyledPara>
          </CatDescription> */}
          <Row>
            <Col md={12}>
              <MasonaryWrap>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3, 1400: 4 }}
                >
                  <Masonry gutter="20px">
                    {data.familyImages.edges.map((item, i) => {
                      return (
                        <ImageContainer key={i}>
                          <Fancybox options={{ infinite: false }}>
                            <a
                              data-fancybox="family"
                              href={
                                item.node.childImageSharp.gatsbyImageData.images
                                  .fallback.src
                              }
                            >
                              <ImageWrap>
                                <GatsbyImage
                                  image={
                                    item.node.childImageSharp.gatsbyImageData
                                  }
                                  alt={item.node.name}
                                />
                              </ImageWrap>
                            </a>
                          </Fancybox>
                        </ImageContainer>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </MasonaryWrap>
            </Col>
          </Row>
        </Container>
      </CatSectionWrap>
    </Layout>
  );
};

export const blessingQuery = graphql`
  query {
    familyImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "family" }
      }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default FamilyPage;
