import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
  // FaTwitter,
} from "react-icons/fa";
import styled from "styled-components";
import { BoxShadow, StyledH1 } from "../../styles/globalStyles";
import { SocialIcon } from "../Footer/FooterComp";

const SectionWrap = styled.section`
  padding: 80px 0;

  form {
    padding-top: 30px;

    .form-label {
      font-size: 13px;
      font-family: "Montserrat-Medium";
    }

    .form-control {
      padding: 0.575rem 1rem;
      border-radius: 8px;
      background-color: #e8a0b042;
      border: 0;
      font-size: 14px;
      box-shadow: none;

      resize: none;
    }
  }

  ${SocialIcon} {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #eee;
    margin-right: 15px;
    color: #fff;
    font-size: 16px;

    &:first-child {
      background-color: #1877f2;

      &:hover {
        box-shadow: 0 5px 10px rgb(0 111 255 / 42%),
          0 3px 6px rgb(24 119 242 / 2%);
      }
    }

    &:nth-child(2) {
      background-color: #ea4c89;

      &:hover {
        box-shadow: 0 5px 10px rgb(255 139 165 / 38%),
          0 3px 6px rgb(255 207 218 / 29%);
      }
    }

    &:nth-child(3) {
      background-color: #1da1f2;

      &:hover {
        box-shadow: 0 10px 20px rgb(29 161 242 / 38%),
          0 3px 6px rgb(29 161 242 / 20%);
      }
    }
  }
`;

const FormWrap = styled.div`
  padding: 0 20px;

  ${StyledH1} {
    color: #e8a0b0;
    font-family: "Montserrat-Bold";
  }

  @media only screen and (min-width: 1200px) {
    padding: 0 60px;
    margin-bottom: 0;
  }

  button.btn {
    padding: 12px 50px;
    border-radius: 8px;
    background: #e8a0b0 !important;
    font-weight: 600;
    margin-top: 30px;
    box-shadow: 0 10px 20px rgb(255 139 165 / 38%),
      0 3px 6px rgb(255 207 218 / 29%);

    &:hover {
      box-shadow: 0 1px 3px rgb(232 160 176), 0 1px 2px rgb(232 160 176 / 30%);
    }
  }
`;

const StyledPara = styled.p``;

const ContactDetails = styled.div`
  padding: 0 20px;

  @media only screen and (min-width: 1200px) {
    padding: 0 60px;
  }
`;

const ContactImageWrap = styled.div`
  ${BoxShadow}
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 50px;
`;

const IconWrap = styled.span``;
const IconText = styled.span`
  display: inline-block;
  margin-left: 20px;
`;

const ContactSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center">
          <Col md={12} lg={10}>
            <Row>
              <Col md={6}>
                <FormWrap className="mb-5 mb-sm-0">
                  <StyledH1 data-aos="fade-up" data-aos-delay="50">
                    Let's talk
                  </StyledH1>
                  <StyledPara data-aos="fade-up" data-aos-delay="100">
                    You can forward your details/requirements directly over the
                    email or call us anytime and you can even connect to our
                    associate franchise holder at the location convenient to
                    you.
                  </StyledPara>

                  <Form
                    target="_blank"
                    action="https://formsubmit.co/51d0f9e7411fea86354fc971591fbcd6"
                    method="POST"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <Form.Group className="mb-3" controlId="formName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control type="text" name="name" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" name="email" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="number" name="number" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formCity">
                      <Form.Label>Area/City</Form.Label>
                      <Form.Control type="text" name="city" />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                      <Form.Label>How Did You Know Us</Form.Label>

                      <Form.Select
                        className="form-control"
                        aria-label="How Did You Know Us"
                        defaultValue={"Select"}
                        name="howyouknowus"
                      >
                        <option value="Select" disabled>
                          Select
                        </option>
                        <option value="Google Search">Google Search</option>
                        <option value="Referred By Others">
                          Referred By Others
                        </option>
                        <option value="Others">Others</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formMessage">
                      <Form.Label>Message</Form.Label>
                      <Form.Control as="textarea" rows={5} name="message" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Send Message
                    </Button>
                  </Form>
                </FormWrap>
              </Col>
              <Col md={6}>
                <ContactDetails data-aos="fade-up" data-aos-delay="50">
                  <ContactImageWrap className="d-none d-md-block">
                    <StaticImage
                      src="../../images/contact-illustration.jpg"
                      alt="illustration"
                    />
                  </ContactImageWrap>

                  <StyledPara className="d-flex">
                    <IconWrap>
                      <FaMapMarkerAlt />
                    </IconWrap>
                    <IconText>
                      931, Sudama Nagar, Indore, Madhya Pradesh, 452009
                    </IconText>
                  </StyledPara>

                  <StyledPara className="d-flex">
                    <IconWrap>
                      <FaEnvelope />
                    </IconWrap>
                    <IconText>
                      <a href="mailto:3dhandscasting@gmail.com">
                        3dhandscasting@gmail.com
                      </a>
                    </IconText>
                  </StyledPara>

                  <StyledPara className="d-flex">
                    <IconWrap>
                      <FaPhoneAlt />
                    </IconWrap>
                    <IconText>+91 91790 88058</IconText>
                  </StyledPara>

                  <StyledPara className="d-flex">
                    <IconWrap>
                      <FaPhoneAlt />
                    </IconWrap>
                    <IconText>+91 98062 31343</IconText>
                  </StyledPara>

                  <StyledPara style={{ lineHeight: 0, marginTop: "30px" }}>
                    <SocialIcon
                      target="_blank"
                      href="https://www.facebook.com/loveplosive/"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      <FaFacebookF />
                    </SocialIcon>
                    <SocialIcon
                      target="_blank"
                      href="https://www.instagram.com/CASTING_CORNER/"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <FaInstagram />
                    </SocialIcon>
                    {/* <SocialIcon href="" data-aos="fade-up" data-aos-delay="150">
                      <FaTwitter />
                    </SocialIcon> */}
                  </StyledPara>
                </ContactDetails>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default ContactSection;
