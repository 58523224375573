import * as React from "react";
import Layout from "../components/Layout/Layout";
import Bannerslider from "../components/Home/Bannerslider";
import WelcomeSection from "../components/Home/WelcomeSection";
//import TestimonialsSection from "../components/Home/TestimonialsSection";
import FormSection from "../components/Home/FormSection";
import {
  CategoryType1,
  CategoryType2,
  CategoryType3,
  CategoryType4,
} from "../components/Home/CategoryTypes";

const IndexPage = () => {
  return (
    <Layout>
      <Bannerslider />
      <WelcomeSection />
      <CategoryType1 />
      <CategoryType2 />
      <CategoryType3 />
      <CategoryType4 />
      {/* <TestimonialsSection /> */}
      <FormSection />
    </Layout>
  );
};

export default IndexPage;
