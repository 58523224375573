import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { StaticImage } from "gatsby-plugin-image";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "styled-components";
import { BoxShadow } from "../../styles/globalStyles";

const SliderWrap = styled.div`
  height: 450px;

  img {
    width: 100%;
    object-fit: cover;
  }

  .swiper {
    background: #000;
    height: 100%;

    .gatsby-image-wrapper{
      height:100%;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    ${BoxShadow}
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &:after {
      color: #000;
      font-size: 20px;
      line-height: 0;
    }
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 4px;
    background: #fff;
    border-radius: 5px;
    transform: scale(0.7);
    opacity: 0.6;

    // @media only screen and (min-width: 991px) {
    //   width: 70px;
    // }

    &:hover,
    &.swiper-pagination-bullet-active {
      background: #000000;
    }
  }
`;

const Bannerslider = () => {
  return (
    <SliderWrap>
      <Swiper
        navigation={true}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{
          dynamicBullets: false,
          clickable: true,
        }}
        speed={500}
        loop="true"
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        <SwiperSlide>
         {/*  <img
            src="../../images/babyh-13.jpg"
            alt="banner"
            className="d-block"
            objectFit="contain"
          /> */}
          <StaticImage
            src="../../images/babyh-13.jpeg"
            alt="IMage2"
            className="d-block"
            objectFit="contain"
          />
        </SwiperSlide>
        <SwiperSlide>
        <StaticImage
            src="../../images/cast-8.jpg"
            alt="IMage2"
            className="d-block"
            objectFit="contain"
          />
        </SwiperSlide>
        <SwiperSlide>
        <StaticImage
            src="../../images/couples/cp-11.jpeg"
            alt="IMage3"
            className="d-block"
            objectFit="contain"/>
        </SwiperSlide>
        <SwiperSlide>
        <StaticImage
            src="../../images/baby_hands_and_feet/babyh-1.jpeg"
            alt="IMage4"
            className="d-block"
            objectFit="contain"/>
        </SwiperSlide>
        <SwiperSlide>
        <StaticImage
            src="../../images/cast-4.jpg"
            alt="IMage5"
            className="d-block"
            objectFit="contain"
          />
        </SwiperSlide>
      </Swiper>
    </SliderWrap>
  );
};

export default Bannerslider;
