import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Col, Container, Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Fancybox from "../../components/Fancybox";
import GlobalBannerComp from "../../components/GlobalBannerComp";
import Layout from "../../components/Layout/Layout";
import {
  // CatDescription,
  CatSectionWrap,
  // CatStyledPara,
  ImageContainer,
  ImageWrap,
  MasonaryWrap,
} from "../../styles/globalStyles";

const BabyHandFeetPage = ({ data }) => {
  return (
    <Layout parent_path="/product">
      <GlobalBannerComp titleText="Baby Hands &amp; Feet" />

      <CatSectionWrap>
        <Container>
          {/* <CatDescription>
            <CatStyledPara>
              Baby Hand and Footprint - We understand how watching your little
              Baby Hand and Footprint when they are tiny fills up your heart and
              makes you complete. We provide Baby Hand and Footprint casting
              which we go onto next level and frame it for your heart to behold
              that divine delight forever.
            </CatStyledPara>
          </CatDescription> */}
          <Row>
            <Col md={12}>
              <MasonaryWrap>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3, 1400: 4 }}
                >
                  <Masonry gutter="20px">
                    {data.babyHandsImages.edges.map((item, i) => {
                      return (
                        <ImageContainer key={i}>
                          <Fancybox options={{ infinite: false }}>
                            <a
                              data-fancybox="baby_hands"
                              href={
                                item.node.childImageSharp.gatsbyImageData.images
                                  .fallback.src
                              }
                            >
                              <ImageWrap>
                                <GatsbyImage
                                  image={
                                    item.node.childImageSharp.gatsbyImageData
                                  }
                                  alt={item.node.name}
                                />
                              </ImageWrap>
                            </a>
                          </Fancybox>
                        </ImageContainer>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </MasonaryWrap>
            </Col>
          </Row>
        </Container>
      </CatSectionWrap>
    </Layout>
  );
};

export const blessingQuery = graphql`
  query {
    babyHandsImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "baby_hands_and_feet" }
      }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default BabyHandFeetPage;
