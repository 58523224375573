import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import FormLeaveMessage from "../Forms/FormLeaveMessage";
import PageTitle from "../PageTitle";

const SectionWrap = styled.section`
  padding: 80px 0;
  background-color: #41266f;
`;

const FormWrap = styled.div`
  padding: 60px;
  background-color: rgb(245, 248, 252);
  border-radius: 5px;

  @media only screen and (max-width: 860px) {
    padding: 60px 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 60px 30px;
  }
`;

const FormSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <FormWrap>
              <Row className="justify-content-center">
                <Col md={10} lg={8}>
                  <PageTitle className="pt-0" titleText="Leave a Message" />
                  <FormLeaveMessage />
                </Col>
              </Row>
            </FormWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default FormSection;
