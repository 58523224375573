import React from "react";
import AboutUsSection from "../components/About/AboutUsSection";
import SocialRatingSection from "../components/About/SocialRatingSection";
import VideoSection from "../components/About/VideoSection";
import GlobalBannerComp from "../components/GlobalBannerComp";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";

const About = () => {
  return (
    <Layout>
      <Seo title="About" />
      <GlobalBannerComp titleText="About" />
      <AboutUsSection />
      <VideoSection />
      <SocialRatingSection />
    </Layout>
  );
};

export default About;
