import styled from "styled-components";

export const ContentSection = styled.div`
  .link-btn {
    padding: 6px 20px;
    display: inline-block;
    border-radius: 3px;
    background: ${(props) => props.color};
    color: #fff;
    margin-top: 20px;
    box-shadow: ${(props) => props.boxshadow};

    &:hover {
      opacity: 0.7;
    }
  }
`;

export const StyledH1 = styled.h1`
  font-family: "Montserrat-Bold";
  font-size: 40px;
  color: ${(props) => props.color};
  text-transform: lowercase;
  position: relative;
  z-index: 1;
  line-height: 1;

  &::first-letter {
    font-size: 100px;
    text-transform: uppercase;
  }
`;

export const StyledPara = styled.p`
  font-size: 15px;
  font-family: "Montserrat-Regular";
  line-height: 30px;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export { default as CategoryType1 } from "./CategoryType1";
export { default as CategoryType2 } from "./CategoryType2";
export { default as CategoryType3 } from "./CategoryType3";
export { default as CategoryType4 } from "./CategoryType4";
