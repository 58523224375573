import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { BoxShadow, FontSize28 } from "../../styles/globalStyles";
import { SectionHeading } from "../Home/WelcomeSection";

const SectionWrap = styled.section`
  padding: 120px 0;

  @media only screen and (max-width: 767px) {
    padding: 60px 0;
  }
`;

const AboutContainer = styled.div``;

const ImageWrap = styled.div`
  padding: 0 40px;

  @media only screen and (max-width: 767px) {
    padding: 40px 0;
  }
`;

const ImageContainer = styled.div`
  ${BoxShadow}
  overflow: hidden;
  border-radius: 5px;
`;

const AboutContentSection = styled.div``;

export const StyledPara = styled.p`
  line-height: 26px;
  font-size: 15px;
`;

const StyledSectionHeading = styled(SectionHeading)`
  ${FontSize28}
`;

const AboutUsSection = () => {
  return (
    <SectionWrap>
      <Container>
        <AboutContainer>
          <Row className="align-items-center">
            <Col md={5}>
              <ImageWrap>
                <ImageContainer>
                  <StaticImage src="../../images/profile-image.jpeg" alt="" />
                </ImageContainer>
              </ImageWrap>
            </Col>
            <Col md={7}>
              <AboutContentSection>
                <StyledSectionHeading
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  3D Hands Casting
                </StyledSectionHeading>
                <StyledPara data-aos="fade-up" data-aos-delay="100">
                  3D Hands Casting aims to embalm the memories of your loving
                  little one etched into your mind by capturing exact absolute
                  replicas of hands and feet in 3D casting.
                </StyledPara>
                <StyledPara data-aos="fade-up" data-aos-delay="150">
                  At 3D Hands Casting, we know how quickly our children grow and
                  we recognise the fast life fades away our blissful memories of
                  their little self. We aim to create a time capsule by
                  designing a bespoke product with you and your baby that
                  includes 3D impressions and photographs. The casting keepsakes
                  are true to life and capture fond loving memories for years
                  together
                </StyledPara>
                <StyledPara
                  className="m-0"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  We have proudly brought treasurable smiles to more than 500
                  families since 2017 . The bliss of seeing so many satisfied
                  faces has endeavoured us to aim for more happy families , more
                  memories to craft in our unique creative fashion.
                </StyledPara>
              </AboutContentSection>
            </Col>
          </Row>
        </AboutContainer>
      </Container>
    </SectionWrap>
  );
};

export default AboutUsSection;
