import styled, { createGlobalStyle, css } from "styled-components";

const textColorPrimary = "#3f3f3f";

const GlobalStyles = createGlobalStyle` 

body {
    font-family: "Montserrat-Medium";
    font-size: 14px;
    color: ${textColorPrimary};
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: ${textColorPrimary};

    &:hover {
        color: tomato;
    }
}

h1,h2,h3,h4,h5,h6 {
  color: inherit;
}



main {
  position: relative;
}

section {
  padding: 120px 0;

  @media only screen and (max-width: 767px) {
    padding: 80px 0;
  }
}

a.btn, 
button.btn {
    border-radius: 4px;
    background: linear-gradient(#323740, #262a31);
    box-shadow: 0 10px 20px rgb(0 0 0 / 25%), 0 3px 6px rgb(0 0 0 / 25%);
    border: 0;
    padding: 8px 25px;
    font-size: 14px;

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.3);
      opacity: 0.8;
      transition: 150ms;
    }
}

`;

export const StyledH1 = styled.h1`
  font-size: 34px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
  font-family: "Montserrat-Medium";
  font-weight: 600;

  @media only screen and (max-width: 768px) {
    font-size: 28px;
  }
`;

export const SectionTitle = styled(StyledH1)`
  position: relative;
  text-transform: uppercase;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100px;
    height: 1px;
    background: purple;
  }
`;

export const FontSize28 = css`
  font-size: 28px;
`;

export const BoxShadow = css`
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
`;

export const CatSectionWrap = styled.div`
  padding: 100px 0;
`;

export const CatDescription = styled.div`
  margin-bottom: 60px;
`;

export const MasonaryWrap = styled.div``;

export const ImageContainer = styled.div`
  img {
    border-radius: 5px;
    width: 100%;
    transition: 0.5s ease;
  }
`;

export const ImageWrap = styled.div`
  border-radius: 5px;
  transition: 0.5s ease;
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 40%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }

  &:hover {
    &::after {
      opacity: 1;
    }

    img {
      transform: scale(1.1);
    }
  }
`;

export const CatStyledPara = styled.p`
  line-height: 26px;
  font-size: 15px;
`;

export default GlobalStyles;
