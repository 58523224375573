import { Link } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";

import styled from "styled-components";
import { StyledH1 } from "../../styles/globalStyles";

const SectionWrap = styled.section`
  padding: 80px 0;
`;

const Content = styled.div``;

const AboutImageSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 0 60px;

  @media only screen and (max-width: 991px) {
    padding: 0 20px;
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

const ImageItem = styled.div`
  @media only screen and (max-width: 767px) {
    margin: 0 !important;
  }
  .gatsby-image-wrapper {
    height: 200px;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @media only screen and (max-width: 767px) {
      width: 100%;
      height: 135px;
    }
  }
`;

export const SectionHeading = styled(StyledH1)`
  position: relative;
  color: #000;

  &::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 3px;
    bottom: 0;
    left: 0;
    background: gold;
  }
`;

// const Quote = styled.p`
//   font-size: 15px;
//   font-family: "Montserrat-Bold";
//   color: #000;
// `;

const StyledPara = styled.p`
  font-size: 15px;
  line-height: 30px;

  a {
    display: inline-block;
    position: relative;
    overflow: hidden;
  }
`;

const WelcomeSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="align-items-center flex-column-reverse flex-md-row">
          <Col md={6}>
            <Content>
              <SectionHeading
                className="mb-4"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Welcome To 3D Hands Casting
              </SectionHeading>
              {/* <Quote data-aos="fade-up" data-aos-delay="100">
                <em>
                  “Some people will touch your life for a brief moment but will
                  leave an imprint for a lifetime”
                </em>
              </Quote> */}
              <StyledPara data-aos="fade-up" data-aos-delay="150">
                Our memories are what we are, what makes us make the decisions
                we make, act as we act and love as we love. We would be nothing
                without our memories but, is it possible to remember everything?
              </StyledPara>
              <StyledPara data-aos="fade-up" data-aos-delay="200">
                The answer is no, but we can take measures so that when it comes
                the time to look back, we have as many memories as we can. How
                you do it ?. Very easy. In this post we tell you how.
                3D Hands Casting strives to capture every detail of the hands
                and feet, through innovative 3D castings available in variety of
                metallic shades, Beautiful imprints guaranteed to touch your
                hearts every time you see them.
              </StyledPara>

              <div className="pt-4" data-aos="fade-up" data-aos-delay="250">
                <Link to="/about" className="btn btn-primary">
                  <div>
                    Read more <FaLongArrowAltRight />
                  </div>
                </Link>
              </div>
            </Content>
          </Col>

          {/* Image */}
          <Col md={6}>
            <AboutImageSection>
              <ImageItem data-aos="fade-up" data-aos-delay="50">
                <StaticImage src="../../images/cast-1.jpg" alt="" />
                <StaticImage src="../../images/cast-2.jpg" alt="" />
              </ImageItem>
              <ImageItem
                style={{ marginTop: "-40px" }}
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <StaticImage src="../../images/cast-7.jpg" alt="" />
                <StaticImage src="../../images/cast-6.jpg" alt="" />
              </ImageItem>
            </AboutImageSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default WelcomeSection;
