import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import PageTitle from "./PageTitle";

const SectionWrap = styled.section`
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: contain;
  color: #fff;
  border-bottom: 1px solid #e6e7e9;

  .page-title-banner {
    & > div {
      padding: 50px 0;
    }
  }

  h1 {
    padding: 40px 80px;
    background: rgba(255, 255, 255, 0.7);
    margin: 0;

    &::after,
    &::before {
      display: none;
    }
  }
`;

const defaultBackground = "/BannerDefaultBg-3.jpg";

const GlobalBannerComp = ({ bgProp, titleText }) => {
  return (
    <SectionWrap
      style={{
        padding: "40px",
        backgroundImage: bgProp
          ? `url(${bgProp})`
          : `url(${defaultBackground})`,
      }}
    >
      <Container>
        <Row>
          <Col md={12} className="page-title-banner">
            <PageTitle titleText={titleText} />
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default GlobalBannerComp;
