import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Fancybox from "../Fancybox";
//import { StyledPara } from "./AboutUsSection";

const SectionWrap = styled.section`
  padding: 120px 0;
  background-color: #f0f8ff;
`;

const VideoWrap = styled.div`
  background: #b7b7b7;
  box-shadow: 0px 48px 100px 0px rgba(17, 12, 46, 0.15);
  height: 350px;
  width: 350px;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 auto;

  a {
    position: relative;
    display: block;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url(/playicon.svg);
      background-size: contain;
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      transition: all 1s ease 0s;
      z-index: 9;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const VideoContentSection = styled.div``;

const VideoSection = () => {
  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col md={4}>
            <VideoContentSection>

              {/* <StyledPara data-aos="fade-up" data-aos-delay="100">
                Mansi and Hardik Jhaveri aspire to never compete with what
                exists in market but simply be undeniably good in what they do.
                With a promise to improvise each day , 3D Hands Casting
                products touch lives of all those who wish to enclose their
                priceless moments for future.
              </StyledPara> */}
            </VideoContentSection>
            <VideoWrap data-aos="fade-up" data-aos-delay="100">
              <Fancybox options={{ infinite: false }}>
                <a
                  data-fancybox="https://youtu.be/O9CGBPVdRFM"
                  href="https://youtu.be/O9CGBPVdRFM"
                >
                  <StaticImage
                    src="../../images/cast-6.jpg"
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Fancybox>
            </VideoWrap>
          </Col>
          <Col md={4}>
            <VideoContentSection>

              {/* <StyledPara data-aos="fade-up" data-aos-delay="100">
                Mansi and Hardik Jhaveri aspire to never compete with what
                exists in market but simply be undeniably good in what they do.
                With a promise to improvise each day , 3D Hands Casting
                products touch lives of all those who wish to enclose their
                priceless moments for future.
              </StyledPara> */}
            </VideoContentSection>
            <VideoWrap data-aos="fade-up" data-aos-delay="100">
              <Fancybox options={{ infinite: false }}>
                <a
                  data-fancybox="https://youtu.be/O9CGBPVdRFM"
                  href="https://youtu.be/O9CGBPVdRFM"
                >
                  <StaticImage
                    src="../../images/cast-6.jpg"
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Fancybox>
            </VideoWrap>
          </Col>

          <Col md={4}>
            <VideoWrap data-aos="fade-up" data-aos-delay="100">
              <Fancybox options={{ infinite: false }}>
                <a
                  data-fancybox="https://youtu.be/O9CGBPVdRFM"
                  href="https://youtu.be/O9CGBPVdRFM"
                >
                  <StaticImage
                    src="../../images/cast-6.jpg"
                    alt=""
                    loading="lazy"
                  />
                </a>
              </Fancybox>
            </VideoWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default VideoSection;
