import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ContentSection, StyledH1, StyledPara } from ".";

const SectionWrap = styled.section`
  background-color: #fafdff;
  position: relative;
  z-index: 1;

  &::before {
    content: attr(id);
    position: absolute;
    top: -79px;
    right: 10px;
    z-index: -1;
    text-transform: uppercase;
    opacity: 0.1;
    font-size: 100px;
    color: #ff5722;
    font-family: "Montserrat-Bold";

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`;

const ImageSection = styled.div`
  display: flex;

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  & > div {
    flex: 1;
    height: 400px;

    @media (max-width: 767px) {
      height: 280px;
    }

    &:first-child {
      .gatsby-image-wrapper {
        border-radius: 10px 0 0 10px;
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 20px;

      & > div {
        height: 190px;

        @media (max-width: 767px) {
          height: 130px;
        }

        &:first-child {
          border-radius: 0 10px 0 0;
        }

        &:nth-child(2) {
          border-radius: 0 0 10px 0;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const CategoryType1 = () => {
  return (
    <SectionWrap id="Baby">
      <Container>
        <Row className="align-items-md-center justify-content-between">
          <Col md={5}>
            <StyledH1
              color="#ff5722"
              className="d-block d-md-none pb-2"
              style={{ fontSize: "30px" }}
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Baby
            </StyledH1>

            <ImageSection>
              <div data-aos="fade-up" data-aos-delay="50">
                <StaticImage
                  src="../../../images/baby_hands_and_feet/babyh-10.jpeg"
                  alt="baby hands"
                />
              </div>
              <div data-aos="fade-up" data-aos-delay="50">
                <StaticImage
                  src="../../../images/baby_hands_and_feet/babyh-12.jpeg"
                  alt="baby hands"
                />
                <StaticImage
                  src="../../../images/baby_hands_and_feet/babyh-17.jpeg"
                  alt="baby hands"
                />
              </div>
            </ImageSection>
          </Col>

          <Col md={6}>
            <ContentSection
              color="#ff5722"
              boxshadow="0 10px 20px rgb(255 205 189), 0 3px 6px rgb(255 252 252)"
            >
              <StyledH1
                color="#ff5722"
                className="d-none d-md-block"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Baby
              </StyledH1>
              <StyledPara data-aos="fade-up" data-aos-delay="50">
                The memories of a baby's hands are unmistakable. 3DBabyHands, an
                imprinting service that casts impressions of the hands for
                parents who want to enjoy those precious moments with their
                child again and again. Our artists capture every sweet detail,
                whether you hold your baby close or have to bring him or her all
                the way from overseas. We multiply the magic so you and your
                family can enjoy multiple impressions for years to come.
              </StyledPara>

              <Link
                to="/product/baby-hands-and-feet"
                className="link-btn"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                View all <FaLongArrowAltRight className="ms-2" />
              </Link>
            </ContentSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CategoryType1;
