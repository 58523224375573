exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-baby-hands-and-feet-jsx": () => import("./../../../src/pages/product/baby-hands-and-feet.jsx" /* webpackChunkName: "component---src-pages-product-baby-hands-and-feet-jsx" */),
  "component---src-pages-product-blessing-hands-jsx": () => import("./../../../src/pages/product/blessing-hands.jsx" /* webpackChunkName: "component---src-pages-product-blessing-hands-jsx" */),
  "component---src-pages-product-couples-jsx": () => import("./../../../src/pages/product/couples.jsx" /* webpackChunkName: "component---src-pages-product-couples-jsx" */),
  "component---src-pages-product-family-jsx": () => import("./../../../src/pages/product/family.jsx" /* webpackChunkName: "component---src-pages-product-family-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */)
}

