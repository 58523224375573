import React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Fancybox from "../../components/Fancybox";
import GlobalBannerComp from "../../components/GlobalBannerComp";
import Layout from "../../components/Layout/Layout";
import {
  // CatDescription,
  CatSectionWrap,
  // CatStyledPara,
  ImageContainer,
  ImageWrap,
  MasonaryWrap,
} from "../../styles/globalStyles";
import { GatsbyImage } from "gatsby-plugin-image";

const BlessingHandPage = ({ data }) => {
  return (
    <Layout parent_path="/product">
      <GlobalBannerComp titleText="Blessing Hands" />

      <CatSectionWrap>
        <Container>
          {/* <CatDescription data-aos="fade-up" data-aos-delay="100">
            <CatStyledPara>
              We understand how watching your little baby’s hands and feet when
              they are tiny fills up your heart and makes you complete. We go
              onto next level and frame it for your heart to behold that divine
              delight forever.
            </CatStyledPara>
          </CatDescription> */}
          <Row>
            <Col md={12}>
              <MasonaryWrap>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3, 1400: 4 }}
                >
                  <Masonry gutter="20px">
                    {data.blessingImages.edges.map((item, i) => {
                      console.log(item);
                      return (
                        <ImageContainer key={i}>
                          <Fancybox options={{ infinite: false }}>
                            <a
                              data-fancybox="blessingHands"
                              href={
                                item.node.childImageSharp.gatsbyImageData.images
                                  .fallback.src
                              }
                            >
                              <ImageWrap>
                                <GatsbyImage
                                  image={
                                    item.node.childImageSharp.gatsbyImageData
                                  }
                                  alt={item.node.name}
                                />
                              </ImageWrap>
                            </a>
                          </Fancybox>
                        </ImageContainer>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </MasonaryWrap>
            </Col>
          </Row>
        </Container>
      </CatSectionWrap>
    </Layout>
  );
};

export default BlessingHandPage;

export const blessingQuery = graphql`
  query {
    blessingImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "blessing_hands" }
      }
      sort: { fields: base, order: ASC }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
