import React from "react";
import ContactSection from "../components/Contact/ContactSection";
import GlobalBannerComp from "../components/GlobalBannerComp";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";

const ContactPage = () => {
  return (
    <Layout>
      <Seo title="Contact Us" />
      <GlobalBannerComp titleText="Contact" />
      <ContactSection />
    </Layout>
  );
};

export default ContactPage;
