import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ContentSection, StyledH1, StyledPara } from ".";

const SectionWrap = styled.section`
  // background-color: #fafdff;
  position: relative;
  z-index: 1;

  &::before {
    content: attr(id);
    position: absolute;
    top: -79px;
    left: 10px;
    z-index: -1;
    text-transform: uppercase;
    opacity: 0.1;
    font-size: 100px;
    color: #3f51b5;
    font-family: "Montserrat-Bold";

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`;

const ImageSection = styled.div`
  display: flex;

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  & > div {
    flex: 1;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      padding-right: 20px;

      .gatsby-image-wrapper {
        &:nth-child(1) {
          border-radius: 10px 0 0 0;
        }

        &:nth-child(2) {
          border-radius: 0 0 0 10px;
        }
      }
    }

    &:nth-child(2) {
      .gatsby-image-wrapper {
        &:nth-child(1) {
          border-radius: 0 10px 0 0;
        }

        &:nth-child(2) {
          border-radius: 0 0 10px 0;
        }
      }
    }

    .gatsby-image-wrapper {
      height: 190px;
    }
  }
`;

const CategoryType2 = () => {
  return (
    <SectionWrap id="Couples">
      <Container>
        <Row className="flex-column-reverse flex-md-row align-items-md-center justify-content-between">
          <Col md={6}>
            <ContentSection
              color="#3f51b5"
              boxshadow="0 10px 20px rgb(219 224 255), 0 3px 6px rgb(230 234 255)"
            >
              <StyledH1
                color="#3f51b5"
                className="d-none d-md-block"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Couples
              </StyledH1>
              <StyledPara data-aos="fade-up" data-aos-delay="50">
                It’s those moments when the magic happens. The closest couples.
                They are always more spontaneous than planned, so we had to
                capture them in an unexpected way. The best way to do it? With a
                pair of hands! We love the idea of capturing this moment in
                time, and we know that you’ll love having your hands available
                for any occasion. To check out all of the different options and
                how they work, go to the link in our bio!
              </StyledPara>
              <Link
                to="/product/couples"
                className="link-btn"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                View all <FaLongArrowAltRight className="ms-2" />
              </Link>
            </ContentSection>
          </Col>
          <Col md={5}>
            <StyledH1
              color="#3f51b5"
              className="d-block d-md-none pb-2"
              style={{ fontSize: "30px" }}
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Couples
            </StyledH1>
            <ImageSection data-aos="fade-up" data-aos-delay="50">
              <div>
                <StaticImage
                  src="../../../images/couples/cp-13.jpeg"
                  alt="Couples"
                />
                <StaticImage
                  src="../../../images/couples/cp-27.jpeg"
                  alt="Couples"
                />
              </div>
              <div>
                <StaticImage
                  src="../../../images/couples/cp-10.jpeg"
                  alt="Couples"
                />
                <StaticImage
                  src="../../../images/couples/cp-36.jpeg"
                  alt="Couples"
                />
              </div>
            </ImageSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CategoryType2;
