import * as React from "react";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { BoxShadow } from "../styles/globalStyles";
import { FaLongArrowAltLeft } from "react-icons/fa";

const SectionWrap = styled.section`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: url("/page-not-found-bg.jpg") no-repeat center;
  background-size: cover;

  h1 {
    font-size: 100px;
    font-weight: 700;
    animation: float 3s linear infinite;
  }

  @keyframes float {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(15px);
    }

    100% {
      transform: translateY(-0px);
    }
  }

  h2 {
    font-size: 30px;
  }

  a {
    display: inline-block;
    padding: 5px 30px;
    text-decoration: none;
    font-size: 14px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #fff;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;
    text-transform: uppercase;

    &:hover {
      svg {
        transform: translateX(-5px);
        transition: 0.5s ease;
      }
    }
  }
`;

const SectionContainer = styled.div`
  padding: 100px 0;
  position: relative;
  border-radius: 5px;
  ${BoxShadow}
  z-index: 1;
  overflow: hidden;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
`;

// markup
const NotFoundPage = () => {
  return (
    <SectionWrap>
      <Container>
        <SectionContainer>
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <Link to="/">
            <FaLongArrowAltLeft /> <span className="ps-1">Back to home</span>
          </Link>
        </SectionContainer>
      </Container>
    </SectionWrap>
  );
};

export default NotFoundPage;
