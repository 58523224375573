import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ContentSection, StyledH1, StyledPara } from ".";

const SectionWrap = styled.section`
  // background-color: #fafdff;
  position: relative;
  z-index: 1;

  &::before {
    content: attr(id);
    position: absolute;
    top: -79px;
    left: 10px;
    z-index: -1;
    text-transform: uppercase;
    opacity: 0.1;
    font-size: 100px;
    color: #2196f3;
    font-family: "Montserrat-Bold";

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`;

const ImageSection = styled.div`
  display: flex;

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  & > div {
    flex: 1;
    height: 400px;

    @media (max-width: 767px) {
      height: 280px;
    }

    &:first-child {
      flex: 0 0 60%;
      max-width: 60%;

      @media (max-width: 767px) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      .gatsby-image-wrapper {
        border-radius: 10px 0 0 10px;
      }
    }

    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 20px;

      & > div {
        height: 120px;

        @media (max-width: 767px) {
          height: 130px;
        }

        &:first-child {
          border-radius: 0 10px 0 0;
        }

        &:nth-child(2) {
          @media (max-width: 767px) {
            display: none;
          }
        }

        &:nth-child(3) {
          border-radius: 0 0 10px 0;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const CategoryType4 = () => {
  return (
    <SectionWrap id="Blessings">
      <Container>
        <Row className="flex-column-reverse flex-md-row align-items-md-center justify-content-between">
          <Col md={6}>
            <ContentSection
              color="#2196f3"
              boxshadow="0 10px 20px rgb(190 226 255), 0 3px 6px rgb(240 249 255)"
            >
              <StyledH1
                color="#2196f3"
                className="d-none d-md-block"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Blessings
              </StyledH1>
              <StyledPara data-aos="fade-up" data-aos-delay="50">
                Parents are special Right?, so why not capture the special
                moments between you and your parents? Capture some hands impressions
                with your mom at a fun spot near her—or make a cute video using
                the convenient hands impression cast. To check out all of the
                different options and how they work, go to the link in our bio!
              </StyledPara>
              <Link
                to="/product/blessing-hands"
                className="link-btn"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                View all <FaLongArrowAltRight className="ms-2" />
              </Link>
            </ContentSection>
          </Col>

          <Col md={5}>
            <StyledH1
              color="#2196f3"
              className="d-block d-md-none pb-2"
              style={{ fontSize: "30px" }}
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Blessings
            </StyledH1>
            <ImageSection data-aos="fade-up" data-aos-delay="50">
              <div>
                <StaticImage
                  src="../../../images/blessing_hands/bh-5.jpeg"
                  alt="baby hands"
                />
              </div>
              <div>
                <StaticImage
                  src="../../../images/blessing_hands/bh-1.jpeg"
                  alt="baby hands"
                />
                <StaticImage
                  src="../../../images/blessing_hands/bh-3.jpeg"
                  alt="baby hands"
                />
                <StaticImage
                  src="../../../images/blessing_hands/bh-9.jpeg"
                  alt="baby hands"
                />
              </div>
            </ImageSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CategoryType4;
