import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ContentSection, StyledH1, StyledPara } from ".";

const SectionWrap = styled.section`
  background-color: #fafdff;
  position: relative;
  z-index: 1;

  &::before {
    content: attr(id);
    position: absolute;
    top: -79px;
    right: 10px;
    z-index: -1;
    text-transform: uppercase;
    opacity: 0.1;
    font-size: 100px;
    color: #009688;
    font-family: "Montserrat-Bold";

    @media only screen and (max-width: 767px) {
      display: none;
    }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }

  & > div {
    height: 190px;
    display: flex;
    justify-content: space-between;

    &:first-child {
      .gatsby-image-wrapper {
        width: calc(50% - 10px);

        &:nth-child(1) {
          border-radius: 10px 0 0 0;
        }

        &:nth-child(2) {
          border-radius: 0 10px 0 0;
        }
      }
    }

    &:nth-child(2) {
      .gatsby-image-wrapper {
        border-radius: 0 0 10px 10px;
      }
    }

    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`;

const CategoryType3 = () => {
  return (
    <SectionWrap id="Family">
      <Container>
        <Row className="align-items-md-center justify-content-between">
          <Col md={5}>
            <StyledH1
              color="#009688"
              className="d-block d-md-none pb-2"
              style={{ fontSize: "30px" }}
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Family
            </StyledH1>
            <ImageSection data-aos="fade-up" data-aos-delay="50">
              <div>
                <StaticImage
                  src="../../../images/couples/cp-13.jpeg"
                  alt="Family"
                />
                <StaticImage
                  src="../../../images/couples/cp-27.jpeg"
                  alt="Family"
                />
              </div>
              <div>
                <StaticImage
                  src="../../../images/family/family-2.jpeg"
                  alt="Family"
                />
              </div>
            </ImageSection>
          </Col>
          <Col md={6}>
            <ContentSection
              color="#009688"
              boxshadow="0 10px 20px rgb(191 220 217), 0 3px 6px rgb(255 255 255)"
            >
              <StyledH1
                color="#009688"
                className="d-none d-md-block"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Family
              </StyledH1>
              <StyledPara data-aos="fade-up" data-aos-delay="50">
                Family, friends and loved ones are unforgettable and indelible
                creations of time and life. These clay impressions capture the
                moments that make our family so special
              </StyledPara>
              <Link
                to="/product/family"
                className="link-btn"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                View all <FaLongArrowAltRight className="ms-2" />
              </Link>
            </ContentSection>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default CategoryType3;
